import sparkGenericDefaults from '../shopify-spark-generic/config.js';

const AutoSyncKey = 'hpdwheels';

const wheelsFields = sparkGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = sparkGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const ignoreFields = sparkGenericDefaults.Widgets.find((w) => w.name === 'Facets').ignoreFields?.filter(
  (f) => !wheelsFields.includes(f) && !tiresFields.includes(f),
);

export default {
  includes: ['shopify-spark-generic'],
  ...sparkGenericDefaults,
  Widgets: [
    ...sparkGenericDefaults.Widgets.filter(
      (w) => !['FacetBarWheels', 'FacetBarTires', 'FilterChips', 'Facets'].includes(w.name),
    ),
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
      initExpandedFacets: ['vendor', ...wheelsFields, ...tiresFields],
    },
    {
      name: 'WheelTireVehicleInfo',
      autoSyncVisualization: {
        apiKey: AutoSyncKey,
        config: {
          height: '100px',
        },
      },
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
